@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Raleway', 'Open Sans', sans-serif;
  background-attachment: fixed;
}
.react-confirm-alert-body-element {
  height: 100vh;
}
.react-confirm-alert-svg {
  display: none;
}
#react-confirm-alert {
  background-color: rgba(0, 0, 0, 0.356);
  position:absolute;
  z-index:100;
  top: 0;
  left:0;
  width:100%;
  height: 100vh;
}
.react-confirm-alert-overlay {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-confirm-alert-body {
  position: relative;
  z-index: 101;
  width:500px;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  color: #464646;
}
.react-confirm-alert-body h1 {
  margin: 0;
  margin-bottom: 10px;
}
.react-confirm-alert-button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 30px;
}
.react-confirm-alert-button-group button {
  width:100px;
  height: 30px;
  border:1px solid #464646;
  background-color: transparent;
  color: #464646;
  font-weight: bold;
  cursor: pointer;
  transition: all ease .3s;
}
.react-confirm-alert-button-group button:hover {
  background-color: #464646;
  color: white;
}
.swal2-container {
  z-index: 9999 !important;
}